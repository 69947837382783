<template>
    <div class="d-flex align-items-center flex-shrink-0" v-if="isSuperAdmin">
        <router-link
            to="/super-admin/feedback/feedback-session/create"
            class="btn main-btn me-5"
            v-if="$route.name !== 'super-admin/create-feedback'"
        >
            <span class="btn-text-desktop">{{ $t('Create Feedback Session') }}</span>
            <span class="btn-text-mobile">{{ $t('Create Feedback') }}</span>
        </router-link>
    </div>
    <div class="d-flex align-items-center flex-shrink-0" v-else-if="showDropDown">
        <div id="kt_header_create-btn">
            <div
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
            >
                <div class="create-btn">
                    <img src="/media/buying/icons/plus-icon.svg" alt="">
                    <span>{{ $t('Create') }}</span>
                </div>

                <div
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded"
                    data-kt-menu="true"
                >
                    <div class="menu-item">
                        <div class="menu-link" @click="$router.push('/business/feedback/request/create')">
                            <img src="/media/buying/icons/request-or-idea.svg" alt="">
                            <span>{{ $t('New Request or Idea') }}</span>
                        </div>
                    </div>
                    <div class="menu-item" v-if="showCreateFeedback">
                        <div class="menu-link" @click="$router.push('/business/feedback/feedback-session/create')">
                            <img src="/media/buying/icons/feedback-session.svg" alt="">
                            <span>{{ $t('New Feedback Session') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "HeaderCreateMenu",
    computed: {
        userInfo() {
            return (store.getters.currentUser && Object.keys(store.getters.currentUser).length)
                ? store.getters.currentUser
                : null
        },
        showDropDown() {
            return (this.userInfo && !this.userInfo.is_bank_user) && this.$route.name !== 'create-feedback-provide';
        },
        isSuperAdmin() {
            return this.userInfo && this.userInfo.is_super_admin;
        },
        showCreateFeedback() {
            return this.userInfo ? this.userInfo.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION) : false;
        }
    }
}
</script>

<style scoped lang="scss">
#kt_header_create-btn {
    margin-right: 6px;

    .create-btn {
        background: #FFFFFF;
        border: 1px solid #CED4F8;
        border-radius: 5px;
        padding: 8.5px 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;

        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #435BF4;
        }
    }

    .menu-sub-dropdown {
        width: 217px;
        background: #FFFFFF;
        border: 1px solid #CED4F8;
        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.13);
        border-radius: 8px;
        overflow: hidden;

        .menu-link {
            height: 37px;
            display: flex;
            align-items: center;
            border-radius: 0;
            border-left: 0;

            img {
                margin-right: 11px;
            }

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #444444;
            }
        }

        .menu-item {
            cursor: pointer;

            &:not(:last-child) {
                .menu-link {
                    border-bottom: 1px solid #CED4F8;
                }
            }

            &:hover {
                .menu-link {
                    background: #f1f3ff;
                }
            }
        }
    }
}
.btn-text-mobile {
    display: none;
}

@media (max-width: 500px) {
    .btn-text-mobile {
        display: block;
    }
    .btn-text-desktop {
        display: none;
    }
}
</style>
