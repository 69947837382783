<template>
    <div class="select-language" :class="{'is-auth': isAuth}">
        <el-select
            v-model="selectedValue"
            :popper-class="'select-language-dropdown' + (isAuth ? ' is-auth' : '')"
            placement="bottom-end"
        >
            <template #prefix>
                <img :src="supportedLanguages[selectedValue].icon_path" width="20" height="20" alt="">
                <span v-if="!isAuth">{{ supportedLanguages[selectedValue].title }}</span>
            </template>
            <el-option-group :label="'Select your Language'">
                <el-option
                    v-for="(item, value) in supportedLanguages"
                    :key="value"
                    :value="value"
                >
                    <div class="select-language__item">
                        <div class="select-language__item--icon">
                            <img :src="item.icon_path" alt="">
                        </div>
                        <p>{{ item.title }}</p>
                        <span>{{ item.subTitle }}</span>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import { SUPPORTED_LANGUAGES } from "@/core/config/constants";

export default {
    name: "SelectLanguage",
    props: {
        isAuth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedValue: 'en',
            supportedLanguages: SUPPORTED_LANGUAGES,
        }
    }
}
</script>
