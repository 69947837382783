
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import {DataService} from "@/core/services/DataService";
import store from "@/store";

export default defineComponent({
    name: "KTAside",
    components: {
        KTMenu
    },
    setup() {
        const { t } = useI18n();
        const userData = DataService.getAuthUserData();

        let appVersion = (userData && userData.is_bank_user) ? process.env.VUE_APP_VERSION_BANK : process.env.VUE_APP_VERSION_BUSINESS;
        if (userData && userData.is_super_admin) appVersion = process.env.VUE_APP_VERSION_SUPER_ADMIN;

        const platformConfigs = computed(() => store.getters.platformConfigs)
        return {
            asideTheme,
            t,
            appVersion,
            platformConfigs
        };
    }
});
