import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

const PlatformConfigs = new PlatformConfigsModel(PlatformTypeEnum.BANK)
export const BankMenuItems_Admin = [
  {
    pages: [
      {
        heading: "Bank Overview",
        translationKey: "Bank Overview",
        route: "/bank/dashboard/bank-overview",
        svgIcon: "/media/buying/menuItems/dashboard.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Product Areas",
        translationKey: "Product Areas",
        route: "/bank/product-areas",
        svgIcon: "/media/buying/icons/product-areas-icon.svg",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/product-areas"
          },
          {
            heading: "Detailed Product Area",
            translationKey: "Detailed Product Area",
            route: "/bank/product-areas/*",
            detailedParts: 4,
          },
        ]
      },
      {
        sectionTitle: "Countries",
        translationKey: "Countries",
        route: "/bank/countries",
        svgIcon: "/media/buying/icons/map-black-icon.svg",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/countries"
          },
          {
            heading: "Detailed Country",
            translationKey: "Detailed Country",
            route: "/bank/countries/*",
            detailedParts: 4,
          },
        ]
      },
      {
        sectionTitle: "Feedback",
        translationKey: "feedback",
        route: "/bank/feedback",
        svgIcon: "/media/buying/menuItems/feedback.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Feedback sessions",
            translationKey: "feedbackSessions",
            route: "/bank/feedback/feedback-sessions"
          },
          {
            heading: "Requests",
            translationKey: "requests",
            route: "/bank/feedback/requests"
          },
          {
            heading: "Ideas",
            translationKey: "ideas",
            route: "/bank/feedback/ideas"
          }
        ]
      },
      // {
      //   sectionTitle: "Reports",
      //   translationKey: "reports",
      //   route: "/bank/reports",
      //   svgIcon: "/media/buying/menuItems/reports.svg",
      //   fontIcon: "bi-app-indicator",
      //   sub: [
      //     {
      //       heading: "Market Report",
      //       translationKey: "marketReports",
      //       route: "/bank/reports/market-reports"
      //     },
      //     {
      //       heading: "Custom Report",
      //       translationKey: "customReports",
      //       route: "/bank/reports/custom-reports"
      //     },
      //   ]
      // },
      {
        sectionTitle: "Customers",
        translationKey: "Customers",
        route: "/bank/customers",
        svgIcon: "/media/buying/menuItems/customers.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/customers/all"
          },
          {
            heading: "Detailed Customer",
            translationKey: "Detailed Customer",
            route: "/bank/customers/customer/*",
            detailedParts: 5,
          },
        ]
      },
      // {
      //   sectionTitle: "Teams",
      //   translationKey: "Teams",
      //   route: "/bank/teams",
      //   svgIcon: "/media/buying/menuItems/teams.svg",
      //   fontIcon: "bi-app-indicator",
      //   sub: [
      //     {
      //       heading: "All Users",
      //       translationKey: "All Users",
      //       route: "/bank/teams/all"
      //     }
      //   ]
      // },
      {
        sectionTitle: "Settings",
        translationKey: "Settings",
        route: "/bank/settings",
        svgIcon: "/media/buying/menuItems/business-settings.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Bank Settings",
            translationKey: "Bank Settings",
            route: "/bank/settings/bank-settings/overview"
          },
          {
            heading: "User Settings",
            translationKey: "User Settings",
            route: "/bank/settings/user-settings/overview"
          },
        ]
      },
      {
        sectionTitle: "Help",
        translationKey: "Help",
        route: "/bank/help",
        svgIcon: "/media/buying/menuItems/help-icon.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Contact Support",
            translationKey: "Contact Support",
            route: PlatformConfigs.platformLinks.Contactus,
            href: PlatformConfigs.platformLinks.Contactus
          },
          {
            heading: "Knowledge Base",
            translationKey: "Knowledge Base",
            route: PlatformConfigs.platformLinks.KnowledgeBase,
            href: PlatformConfigs.platformLinks.KnowledgeBase
          }
        ]
      }
    ]
  }
]
export const BankMenuItems_User = [
  {
    pages: [
      {
        heading: "Bank Overview",
        translationKey: "Bank Overview",
        route: "/bank/dashboard/bank-overview",
        svgIcon: "/media/buying/menuItems/dashboard.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Product Areas",
        translationKey: "Product Areas",
        route: "/bank/product-areas",
        svgIcon: "/media/buying/icons/product-areas-icon.svg",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/product-areas"
          },
          {
            heading: "Detailed Product Area",
            translationKey: "Detailed Product Area",
            route: "/bank/product-areas/*",
            detailedParts: 4,
          },
        ]
      },
      {
        sectionTitle: "Countries",
        translationKey: "Countries",
        route: "/bank/countries",
        svgIcon: "/media/buying/icons/map-black-icon.svg",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/countries"
          },
          {
            heading: "Detailed Country",
            translationKey: "Detailed Country",
            route: "/bank/countries/*",
            detailedParts: 4,
          },
        ]
      },
      {
        sectionTitle: "Feedback",
        translationKey: "feedback",
        route: "/bank/feedback",
        svgIcon: "/media/buying/menuItems/feedback.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Feedback sessions",
            translationKey: "feedbackSessions",
            route: "/bank/feedback/feedback-sessions"
          },
          {
            heading: "Requests",
            translationKey: "requests",
            route: "/bank/feedback/requests"
          },
          {
            heading: "Ideas",
            translationKey: "ideas",
            route: "/bank/feedback/ideas"
          }
        ]
      },
      // {
      //   sectionTitle: "Reports",
      //   translationKey: "reports",
      //   route: "/bank/reports",
      //   svgIcon: "/media/buying/menuItems/reports.svg",
      //   fontIcon: "bi-app-indicator",
      //   sub: [
      //     {
      //       heading: "Market Report",
      //       translationKey: "marketReports",
      //       route: "/bank/reports/market-reports"
      //     },
      //     {
      //       heading: "Custom Report",
      //       translationKey: "customReports",
      //       route: "/bank/reports/custom-reports"
      //     },
      //   ]
      // },
      {
        sectionTitle: "Customers",
        translationKey: "Customers",
        route: "/bank/customers",
        svgIcon: "/media/buying/menuItems/customers.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/bank/customers/all"
          },
          {
            heading: "Detailed Customer",
            translationKey: "Detailed Customer",
            route: "/bank/customers/customer/*",
            detailedParts: 5,
          },
        ]
      },
      // {
      //   sectionTitle: "Teams",
      //   translationKey: "Teams",
      //   route: "/bank/teams",
      //   svgIcon: "/media/buying/menuItems/teams.svg",
      //   fontIcon: "bi-app-indicator",
      //   sub: [
      //     {
      //       heading: "All Users",
      //       translationKey: "All Users",
      //       route: "/bank/teams/all"
      //     }
      //   ]
      // },
      {
        sectionTitle: "Settings",
        translationKey: "Settings",
        route: "/bank/settings",
        svgIcon: "/media/buying/menuItems/business-settings.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          // {
          //   heading: "Bank Settings",
          //   translationKey: "Bank Settings",
          //   route: "/bank/settings/bank-settings/overview"
          // },
          {
            heading: "User Settings",
            translationKey: "User Settings",
            route: "/bank/settings/user-settings/overview"
          },
        ]
      },
      {
        sectionTitle: "Help",
        translationKey: "Help",
        route: "/bank/help",
        svgIcon: "/media/buying/menuItems/help-icon.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Contact Support",
            translationKey: "Contact Support",
            route: PlatformConfigs.platformLinks.Contactus,
            href: PlatformConfigs.platformLinks.Contactus
          },
          {
            heading: "Knowledge Base",
            translationKey: "Knowledge Base",
            route: PlatformConfigs.platformLinks.KnowledgeBase,
            href: PlatformConfigs.platformLinks.KnowledgeBase
          }
        ]
      }
    ]
  }
]
