import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = {
  id: "kt_content_container",
  class: "container-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_WalkthroughModal = _resolveComponent("WalkthroughModal")!
  const _component_CookieInfoBar = _resolveComponent("CookieInfoBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { breadcrumbs: _ctx.breadcrumbs }, null, 8, ["breadcrumbs"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["post d-flex flex-column-fluid ps-lg-4 ps-0", _ctx.breadcrumbs.subTitle ? 'mt-12' : 'mt-10'])
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isInitialDataReady)
                ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]),
        (_ctx.footerDisplay)
          ? (_openBlock(), _createBlock(_component_KTFooter, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    (_ctx.isInitialDataReady && _ctx.isFirstLogin)
      ? (_openBlock(), _createBlock(_component_WalkthroughModal, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieInfoBar)
  ], 64))
}