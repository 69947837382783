
import { computed, defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/buying-teams/shared-components/utils/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  asideEnabled,
  contentWidthFluid,
  footerDisplay,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay
} from "@/core/helpers/config";
import InitialDataResolver from "@/core/services/InitialDataResolver";
import WalkthroughModal from "@/buying-teams/shared-components/utils/WalkthroughModal.vue";
import { DataService } from "@/core/services/DataService";
import CookieInfoBar from "@/buying-teams/shared-components/cookie-modal/CookieInfoBar.vue";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "Layout",
  components: {
    CookieInfoBar,
    WalkthroughModal,
    KTAside,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let isInitialDataReady = ref(false);
    let isFirstLogin = ref(false);

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.getBreadcrumbs;
    });

    onMounted(async () => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      const authUser = DataService.getAuthUserData();
      const type = authUser?.is_super_admin ?
        PlatformTypeEnum.SUPER_ADMIN : (authUser?.is_bank_user ?
          PlatformTypeEnum.BANK :
          PlatformTypeEnum.BUSINESS
        );

      if (authUser && !authUser.is_setup_finished) {
        if (authUser.is_bank_user) {
          DataService.removeAllData();
          JwtService.destroyToken();
        } else {
          DataService.removeAllData();
          JwtService.destroyToken();
        }
        redirectToRouteWithName('sign-in')
        return;
      }
      isInitialDataReady.value = false;
      InitialDataResolver
        .init(type)
        .then(() => {
          isInitialDataReady.value = true;
        })
        .finally(() => {
          isFirstLogin.value = DataService.getUserFirstLogin()
          if (isFirstLogin.value) {
            DataService.destroyUserFirstLogin()
          }

          // disable page loading
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        });

      await nextTick(() => {
        reinitializeComponents();
      });

    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "sign-in" });
        }

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      footerDisplay,
      isInitialDataReady,
      isFirstLogin
    };
  }
});
