<template>
  <div class="walk-through">
    <el-dialog
        v-model="isFirstModal"
        center
        width="959px"
        :show-close="true"
        @close="isFirstModal = false"
        custom-class="first-modal"
    >
      <div class="walk-through__firstModal">
        <div class="firstModal-content">
          <div class="firstModal-content__animation">
            <img src="/media/logos/logo-big.svg" alt="logo big">
          </div>
          <div class="animation-overview" :class="{'hide-overview': animationOverview}"></div>
          <div class="firstModal-content__body">
            <img src="/media/misc/startup_flatline1.svg" alt="startup flat line">
            <h1>{{ $t('Simple. Clean. Effective.') }}</h1>
            <p v-html="$t('CXFacts is quite self-explanatory. let us guide you in 5 easy steps for a seamless experience!')"></p>
            <button class="btn main-btn px-15 get-started" @click="() => {
              isFirstModal = false
              isSecondModal = true
              currentStep = 1
            }">
              {{ $t("Let's get started") }}
            </button>
            <small>{{ $t('Following through all steps is recommended') }}</small>
          </div>
        </div>
      </div>
    </el-dialog>

    <template v-if="isSecondModal">
      <div class="steps-modal__overview" :class="'overview--step-' + currentStep"></div>
      <div class="steps-modal" :class="'current--step-' + currentStep">
        <div class="steps-modal__content">
          <div class="steps-modal__slider" ref="step-slider">
            <div class="steps-modal__slider--top">
              <h3>{{ $t('Introduction') }}</h3>
              <button @click="skipAll">{{ currentStep === steps.length ? $t('Continue') : $t('Skip All') }}</button>
            </div>

            <el-carousel
              ref="steps-slider"
              :autoplay="false"
              :loop="false"
              trigger="click"
              arrow="always"
              indicator-position="outside"
              @change="sliderChange"
            >
              <el-carousel-item v-for="item in steps" :key="item">
                <div class="steps-modal__slider--item">
                  <div class="slider-icon">
                    <img :src="item.icon" alt="">
                  </div>
                  <div class="slider-content">
                    <h4>{{ $t(item.title) }}</h4>
                    <p>{{ $t(item.text) }}</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>

            <div class="step-6-text" v-if="currentStep === 6">
              {{ $t('CLICK ON FEEDBACK SESSIONS TO CONTINUE') }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "WalkthroughModal",
  data() {
    return {
      isFirstModal: false,
      isSecondModal: false,
      animationOverview: false,
      currentStep: 0,
      scrollY: 0,
      steps: [
        {
          icon: '/media/misc/sideNavigation-icon.svg',
          title: 'Side Navigation',
          text: 'This is your control panel, jump into any section of your application hassle-free!'
        },
        {
          icon: '/media/misc/filter-icon.svg',
          title: 'Filters',
          text: 'Filter through Banks, Countries, and Time Period to tailor your dashboard according to your need.'
        },
        {
          icon: '/media/misc/info-icon.svg',
          title: 'Info Center',
          text: 'Quick glance to most relevant data tailored for you in this section and quickly reply to live feedback sessions.'
        },
        {
          icon: '/media/misc/speedometer-icon.svg',
          title: 'Product Area Scores',
          text: 'Scan through valuable, all-time product area performance on your banking partners. Use tabs on top to switch between stats sequences.'
        },
        {
          icon: '/media/misc/idea-icon.svg',
          title: 'Requests & Ideas',
          text: 'This section shows all the Requests and Ideas made to your Banking partners globally. You can view all open requests/ideas to gain a better insight for your next dialogue meeting.'
        },
        // {
        //   icon: '/media/misc/feedback-icon.svg',
        //   title: "Let's create a Feedback Session",
        //   text: 'It is super easy to create your own feedback session and push it forward. These sessions add huge insights to your knowledge and help in more impactful team discussions.'
        // },
        // {
        //   icon: '/media/misc/time-icon.svg',
        //   title: 'Almost Done!',
        //   text: 'Click on Create Feedback and follow through the steps to launch your first feedback session!'
        // }
      ],
      sideNavigationElement: null,
      filterCardElement: null,
      infoChartsElement: null,
      productAreasScoresElement: null,
      requestsIdeasElement: null
    }
  },
  methods: {
    sliderChange(e) {
      this.currentStep = e + 1

      if (this.currentStep <= 6 && !this.$route.path.includes('dashboard')) {
        this.$router.push('/business/dashboard')
      }

      if (this.currentStep === 3) {
        this.scrollTo(0, this.infoChartsElement.offsetTop - 140)
      } else if (this.currentStep === 4) {
        this.scrollTo(0, this.productAreasScoresElement.offsetTop - 140)
      } else if (this.currentStep === 5) {
        this.scrollTo(0, this.requestsIdeasElement.offsetTop - 140)
      } else {
        this.scrollTo(0, 0)
      }

      if (this.currentStep === 6 || this.currentStep === 7) {
        this.openFeedbackMenu(0, 1) // Open Feedback menu
      } else {
        this.openFeedbackMenu(1, 0) // Open Dashboard menu
      }
    },
    scrollTo(x, y) {
      window.scrollTo({
        top: y,
        left: x,
        behavior: 'smooth'
      })
    },
    skipAll() {
      this.isFirstModal = false
      this.isSecondModal = false
      this.currentStep = null
    },
    setStyles(top, left, right) { // Set Stepper Slider styles
      let stepsSlider = this.$refs['step-slider'];
      if (!stepsSlider) return;
      stepsSlider.style.top = top;
      stepsSlider.style.left = left;
      stepsSlider.style.right = right;
    },
    setPositions(value) { // Set Stepper Slider position
      switch (value) {
        case 1:
          return this.setStyles(
            `${94 - this.scrollY}px`,
            `${this.sideNavigationElement.offsetWidth + 12}px`,
            'revert'
          )
        case 2:
          return this.setStyles(
            `${((this.filterCardElement.offsetTop + this.filterCardElement.clientHeight) + 12) - this.scrollY}px`,
            'revert',
            '26px'
          )
        case 3:
          return this.setStyles(
            `${((this.infoChartsElement.offsetTop + this.infoChartsElement.clientHeight) + 12) - this.scrollY}px`,
            'revert',
            '22%'
          )
        case 4:
          return this.setStyles(
            `${((this.productAreasScoresElement.offsetTop - this.productAreasScoresElement.clientHeight) + 120) - this.scrollY}px`,
            'revert',
            '23.8%'
          )
        case 5:
          return this.setStyles(
            `${this.requestsIdeasElement.offsetTop  - this.scrollY}px`,
            'revert',
            `${this.requestsIdeasElement.clientWidth + 42}px`
          )
        case 6:
          return this.setStyles(
            `${94 - this.scrollY}px`,
            `${this.sideNavigationElement.offsetWidth + 12}px`,
            'revert'
          )
        case 7:
          return this.setStyles(
            `${94 - this.scrollY}px`,
            '50%',
            'revert'
          )
      }
    },
    handleScrollResize () {
      if (this.isSecondModal) {
        this.scrollY = window.scrollY
        this.setPositions(this.currentStep)
      }
    },
    openFeedbackMenu(dashboard, feedback) { // Open or Close menu item
      let menuItems = document.querySelectorAll('.menu-item.menu-accordion')

      setTimeout(() => {
        if (menuItems[dashboard]) {
          let subMenu = menuItems[dashboard].querySelector('.menu-sub')
          this.removeClasses(menuItems[dashboard], 'show')
          this.removeClasses(subMenu, 'show')
        }
        if (menuItems[feedback]) {
          let subMenu = menuItems[feedback].querySelector('.menu-sub')
          menuItems[feedback].classList.add('show')
          subMenu.classList.add('show')
        }
      }, 0)
    },
    removeClasses(element, className, newClassName = '') { // Remove (Add) classes
      if (!element) return;
      let classes = element.className.split(" ").filter((v) => {
        return v.lastIndexOf(className, 0) !== 0;
      });

      element.className = classes.join(" ").trim();
      if (newClassName) {
        element.classList.add(newClassName)
      }
    },
    getElements() {
      this.sideNavigationElement = document.getElementById('kt_aside')
      this.filterCardElement = document.getElementById('dashboard-filter-card')
      this.infoChartsElement = document.getElementById('info-charts')
      this.productAreasScoresElement = document.getElementById('product-areas-scores')
      this.requestsIdeasElement = document.getElementById('requests-ideas')
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScrollResize);
    window.addEventListener("resize", this.handleScrollResize);

    this.getElements()

    if (this.$route.path.includes('dashboard')) {
      setTimeout(() => {
        this.isFirstModal = true
        this.animationOverview = true
      }, 100)
    }
  },
  watch: {
    currentStep(value) {
      this.$nextTick(() => {
        this.removeClasses(
          document.body,
          'currentStep',
          'currentStep--step-' + value
        )

        this.setPositions(value)
      })
    },
    '$route': {
      handler(newVal) {
        this.$nextTick(() => {
          this.getElements()
        })
        if (newVal.path.includes('feedback-sessions') && this.currentStep === 6) {
          this.currentStep = 7
          this.$refs['steps-slider'].next()
        }
        if (
          (
            !newVal.path.includes('feedback-sessions') &&
            !newVal.path.includes('dashboard')
          ) || newVal.path.includes('create')
        ) {
          this.isSecondModal = false
          this.isFirstModal = false
          this.currentStep = 0
          this.removeClasses(document.body, 'currentStep')
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .walk-through {
    .currentStep {
      &--step-1 {
        .steps-modal {
          &__overview {
            z-index: 100;
          }
        }
      }
    }
    .el-overlay-dialog  {
      display: flex;
      align-items: center;
    }
    .first-modal {
      margin-top: 0;
      .el-dialog {
        &__header {
          padding: 0;
        }
        &__body {
          padding: 0;
        }
        &__headerbtn {
          z-index: 4;
        }
      }
    }
    &__firstModal {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: auto;

      .firstModal-content {
        position: relative;
        overflow: hidden;
        border-radius: 14px;

        .animation-overview {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: #fff;
          opacity: 0;
          visibility: hidden;
          -webkit-animation: overview 2s ease 1 normal;
          -moz-animation: overview 2s ease 1 normal;
          -o-animation: overview 2s ease 1 normal;
          animation: overview 2s ease 1 normal;
        }

        &__body {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img {
            margin-top: 91px;
            margin-bottom: 25px;
          }
          h1 {
            font-weight: 700;
            font-size: 48px;
            line-height: 103.02%;
            color: #212121;
            margin-bottom: 25px;
          }
          p {
            font-weight: 300;
            font-size: 24px;
            line-height: 141.02%;
            color: #212121;
            padding: 0 12%;
            text-align: center;
          }
          .get-started {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            margin-top: 65px;
          }
          small {
            margin-top: 16px;
            font-weight: 300;
            font-size: 12px;
            line-height: 141.02%;
            color: rgba(33, 33, 33, 0.5);
            margin-bottom: 66px;
          }
        }

        &__animation {
          img {
            position: absolute;
            top: 39px;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.4);
            animation-duration: 2s;
            animation-name: slidein;
            z-index: 2;
          }
        }
      }
    }

    .steps-modal {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 999999;

      &.current--step-7 {
        z-index: 200;

        .steps-modal__slider {
          transform: translateX(-50%);
        }
      }

      &.current--step-6 {
        z-index: 200;
        .el-carousel__arrow--left {
          left: 60px;
        }
        .el-carousel__arrow--right {
          visibility: hidden;
        }
        .el-carousel__indicators {
          visibility: hidden;
        }
      }

      &__content {
        position: relative;
        width: 100%;
        height: 100%;
      }
      &__slider {
        background: #435BF4;
        box-shadow: 0 8px 18px rgba(0, 0, 0, 0.09);
        border-radius: 13px;
        padding: 15px 18px;
        width: max-content;
        min-width: 615px;
        position: fixed;

        .step-6-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: rgba(255, 255, 255, 0.5);
          position: absolute;
          bottom: 22px;
          left: 50%;
          transform: translateX(-50%);
          user-select: none;
        }
        .el-carousel {
          margin: 0 -18px;

          &__container {
            position: revert;
            height: 150px;
          }
          &__arrow {
            top: inherit;
            bottom: 0;
            transform: revert;
            width: revert;
            height: 32px;
            background: transparent;
            font-size: 18px;
            &--left {
              left: 35%;
            }
            &--right {
              right: 35%;
            }
          }
          &__button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
          &__indicator {
            button {
              background: rgba(0, 0, 0, 0.17);
              opacity: 1;
            }
            &.is-active button {
              background: #FFFFFF;
            }
          }
        }
        &--item {
          padding: 18px;
          display: flex;
          .slider-icon {
            width: 75px;
            height: 75px;
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
          }
          .slider-content {
            width: calc(100% - 100px);
            h4 {
              font-weight: 700;
              font-size: 18px;
              line-height: 22px;
              color: #FFFFFF;
              margin-bottom: 9px;
            }
            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #FFFFFF;
            }
          }
        }
        &--top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
          }
          button {
            background: transparent;
            border: none;
            padding: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
          }
        }
      }
      &__overview {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 200;

        &.overview--step-7 {
          background-image: url("/media/misc/step-7-overview.png");
          background-color: transparent;
          background-size: cover;
          background-position: top right;
        }
      }
    }
  }

  @keyframes slidein {
    0% {
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    70% {
      top: 60%;
      transform: translate(-50%, -60%) scale(1.05);
    }

    100% {
      top: 39px;
      transform: translate(-50%, -50%) scale(0.4);
    }
  }


  @keyframes overview {
    0% {
      opacity: 1;
      visibility: visible;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @-webkit-keyframes overview {
    0% {
      opacity: 1;
      visibility: visible;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  @-moz-keyframes overview {
    0% {
      opacity: 1;
      visibility: visible;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  @-o-keyframes overview {
    0% {
      opacity: 1;
      visibility: visible;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes overview {
    0% {
      opacity: 1;
      visibility: visible;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }


</style>
