export const SuperAdminMenuItems = [
    {
        pages: [
            {
                heading: "Dashboard",
                svgIcon: "/media/buying/menuItems/dashboard.svg",
                translationKey: "dashboard",
                route: "/super-admin/dashboard"
            },
            {
                heading: "User Management",
                svgIcon: "/media/buying/menuItems/teams.svg",
                translationKey: "User Management",
                route: "/super-admin/user-management"
            },
        ]
    }
]
