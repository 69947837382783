import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

const PlatformConfigs = new PlatformConfigsModel(PlatformTypeEnum.BUSINESS);

export const BusinessMenuItems_Admin = [
  {
    pages: [
      {
        sectionTitle: "Dashboard",
        translationKey: "dashboard",
        route: "/business/dashboard",
        svgIcon: "/media/buying/menuItems/dashboard.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/business/dashboard/overview"
          },
          {
            heading: "Banks",
            translationKey: "Banks",
            route: "/business/dashboard/banks"
          },
          {
            heading: "Product Areas",
            translationKey: "Product Areas",
            route: "/business/dashboard/product-areas"
          },
          {
            heading: "Countries",
            translationKey: "Countries",
            route: "/business/dashboard/countries"
          },
          {
            heading: "Compare Banks",
            translationKey: "compareBanks",
            route: "/business/dashboard/compare/banks",
          },
        ]
      },
      {
        sectionTitle: "Feedback",
        translationKey: "feedback",
        route: "/business/feedback",
        svgIcon: "/media/buying/menuItems/feedback.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Feedback sessions",
            translationKey: "feedbackSessions",
            route: "/business/feedback/feedback-sessions"
          },
          {
            heading: "Requests",
            translationKey: "requests",
            route: "/business/feedback/requests"
          },
          {
            heading: "Ideas",
            translationKey: "ideas",
            route: "/business/feedback/ideas"
          }
        ]
      },
      {
        sectionTitle: "Reports",
        translationKey: "reports",
        route: "/business/reports",
        svgIcon: "/media/buying/menuItems/reports.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          // {
          //   heading: "Market Report",
          //   translationKey: "marketReports",
          //   route: "/business/reports/market-reports"
          // },
          {
            heading: "Custom Report",
            translationKey: "customReports",
            route: "/business/reports/custom-reports"
          },
        ]
      },
      {
        sectionTitle: "Teams",
        translationKey: "teams",
        route: "/business/teams",
        svgIcon: "/media/buying/menuItems/teams.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "View Colleagues",
            translationKey: "All Users",
            route: "/business/teams/all"
          }
        ]
      },
      {
        sectionTitle: "Settings",
        translationKey: "Settings",
        route: "/business/settings",
        svgIcon: "/media/buying/menuItems/business-settings.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Business Settings",
            translationKey: "Business Settings",
            route: "/business/settings/business-settings/overview"
          },
          {
            heading: "User Settings",
            translationKey: "User Settings",
            route: "/business/settings/user-settings/overview"
          },
        ]
      },
      {
        sectionTitle: "Help",
        translationKey: "Help",
        route: "/business/help",
        svgIcon: "/media/buying/menuItems/help-icon.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Contact Support",
            translationKey: "Contact Support",
            route: 'https://cxfacts.com/contactus/',
            href: 'https://cxfacts.com/contactus/'
          },
          {
            heading: "Knowledge Base",
            translationKey: "Knowledge Base",
            route: 'https://cxfacts.com/business/knowledgebase/',
            href: 'https://cxfacts.com/business/knowledgebase/'
          }
        ]
      },
    ]
  }
];
export const BusinessMenuItems_User = [
  {
    pages: [
      {
        sectionTitle: "Dashboard",
        translationKey: "dashboard",
        route: "/business/dashboard",
        svgIcon: "/media/buying/menuItems/dashboard.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Overview",
            translationKey: "Overview",
            route: "/business/dashboard/overview"
          },
          {
            heading: "Banks",
            translationKey: "Banks",
            route: "/business/dashboard/banks"
          },
          {
            heading: "Product Areas",
            translationKey: "Product Areas",
            route: "/business/dashboard/product-areas"
          },
          {
            heading: "Countries",
            translationKey: "Countries",
            route: "/business/dashboard/countries"
          },
          {
            heading: "Compare Banks",
            translationKey: "compareBanks",
            route: "/business/dashboard/compare/banks",
          },
        ]
      },
      {
        sectionTitle: "Feedback",
        translationKey: "feedback",
        route: "/business/feedback",
        svgIcon: "/media/buying/menuItems/feedback.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Feedback sessions",
            translationKey: "feedbackSessions",
            route: "/business/feedback/feedback-sessions"
          },
          {
            heading: "Requests",
            translationKey: "requests",
            route: "/business/feedback/requests"
          },
          {
            heading: "Ideas",
            translationKey: "ideas",
            route: "/business/feedback/ideas"
          }
        ]
      },
      {
        sectionTitle: "Reports",
        translationKey: "reports",
        route: "/business/reports",
        svgIcon: "/media/buying/menuItems/reports.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          // {
          //   heading: "Market Report",
          //   translationKey: "marketReports",
          //   route: "/business/reports/market-reports"
          // },
          {
            heading: "Custom Report",
            translationKey: "customReports",
            route: "/business/reports/custom-reports"
          },
        ]
      },
      {
        sectionTitle: "Teams",
        translationKey: "teams",
        route: "/business/teams",
        svgIcon: "/media/buying/menuItems/teams.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "View Colleagues",
            translationKey: "All Users",
            route: "/business/teams/all"
          }
        ]
      },
      {
        sectionTitle: "Settings",
        translationKey: "Settings",
        route: "/business/settings",
        svgIcon: "/media/buying/menuItems/business-settings.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "User Settings",
            translationKey: "User Settings",
            route: "/business/settings/user-settings/overview"
          },
        ]
      },
      {
        sectionTitle: "Help",
        translationKey: "Help",
        route: "/business/help",
        svgIcon: "/media/buying/menuItems/help-icon.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Contact Support",
            translationKey: "Contact Support",
            route: 'https://cxfacts.com/contactus/',
            href: 'https://cxfacts.com/contactus/'
          },
          {
            heading: "Knowledge Base",
            translationKey: "Knowledge Base",
            route: 'https://cxfacts.com/business/knowledgebase/',
            href: 'https://cxfacts.com/business/knowledgebase/'
          }
        ]
      },
    ]
  }
];
