
import {defineComponent} from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import store from "@/store";
import HeaderCreateMenu from "@/buying-teams/shared-components/utils/HeaderCreateMenu.vue";
import { PagesForShowCountOfItems } from "@/core/config/constants";
import SelectLanguage from "@/buying-teams/shared-components/utils/SelectLanguage.vue";

export default defineComponent({
  name: "KTHeader",
  props: {
    breadcrumbs: Object,
  },
  components: {
    SelectLanguage,
    HeaderCreateMenu,
    KTTopbar
  },
  data() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay
    }
  },
  computed: {
    showCount() {
      return PagesForShowCountOfItems.filter((item) => this.$route.path.includes(item)).length;
    }
  }
});
