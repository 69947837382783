import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47b2d2e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDropdown = _resolveComponent("UserDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserDropdown)
  ]))
}