import { DataService } from "@/core/services/DataService";
import { BusinessMenuItems_Admin, BusinessMenuItems_User } from "@/core/config/BusinessMenuItems";
import { BankMenuItems_Admin, BankMenuItems_User } from "@/core/config/BankMenuItems";
import { SuperAdminMenuItems } from "@/core/config/SuperAdminMenuItems";

const user = DataService.getAuthUserData();

export const businessMenuConfigs: any = user ? (user.isAdmin ? BusinessMenuItems_Admin : BusinessMenuItems_User) : [];
export const bankMenuConfigs: any = user ? (user.isAdmin ? BankMenuItems_Admin : BankMenuItems_User) : [];
export const superAdminMenuConfigs: any = SuperAdminMenuItems;

// TODO change name 'CleanMainMenuConfig


// EXAMPLE 3 LEVEL

// {
//   pages: [
//     {
//       sectionTitle: "pages",
//       route: "/pages",
//       svgIcon: "/media/buying/icons/general/gen022.svg",
//       fontIcon: "bi-archive",
//       sub: [
//         {
//           sectionTitle: "profile",
//           route: "/profile",
//           sub: [
//             {
//               heading: "profileOverview",
//               route: "/crafted/pages/profile/overview",
//             }
//           ],
//         },
//       ],
//     }
//   ],
// }
