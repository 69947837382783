
import {computed, defineComponent, onMounted, ref} from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";

// TODO change name 'CleanMainMenuConfig
import {bankMenuConfigs, businessMenuConfigs, superAdminMenuConfigs} from "@/core/config/CleanMainMenuConfig";
import store from "@/store";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const inviteCustomersRoute = {
      heading: "Invite Customers",
      translationKey: "Invite Customers",
      route: "/bank/customers/invite"
    };

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const userData: any = computed(() => {
      return store.getters.currentUser;
    })

    const routerLinkExactActive = (item) => {
      if (!item.detailedParts) return 'menu-link';
      let itemParts = route.path.split('/');
      if (itemParts.length >= item.detailedParts && item.route.split('/')[2] === itemParts[2]) {
          return 'menu-link active router-link-exact-active';
      } else return 'menu-link';
    }

    const setInviteCustomersRoute = (user) => {
      if (user?.can(BankUserPermissionTypeEnum.INVITE_CUSTOMERS)) {
        bankMenuConfigs[0].pages.forEach((r: any) => {
          if (r.route === '/bank/customers') {
            if (!(r.sub.filter((sub: any) => sub.route === '/bank/customers/invite').length)) {
              r.sub.push(inviteCustomersRoute);
            }
          }
        })
      }
    }

    const menuItems = computed(() => {
      if (userData.value!.is_super_admin) {
        return superAdminMenuConfigs
      } else {
        if (userData.value!.is_bank_user) {
          setInviteCustomersRoute(userData.value);
          return bankMenuConfigs;
        } else {
          return businessMenuConfigs;
        }
      }
    });

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
      menuItems,
      routerLinkExactActive
    };
  }
});
