<template>
  <el-dropdown trigger="click" placement="bottom-end">
    <div class="cursor-pointer d-flex align-items-center ms-lg-3">
      <div class="user-image me-3">
        <img :src="userInfo.icon_path" alt="metronic" />
      </div>
      <span class="user-info symbol symbol-30px symbol-md-40px d-sm-block d-none" v-if="userInfo && Object.keys(userInfo).length">
        {{ userInfo.getFullName() }}
      </span>
    </div>
    <template #dropdown>
      <el-dropdown-menu class="user-dropdown">
        <KtUserMenu />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import store from "@/store";
import KtUserMenu from "@/layout/header/partials/UserMenu";

export default {
  name: "UserDropdown",
  components: {KtUserMenu},
  computed: {
    userInfo() {
      return store.getters.currentUser
    }
  }
}
</script>

<style lang="scss">
.user-dropdown {
  padding: 0;
  background: transparent;
  border: none !important;
  box-shadow: none;
  .menu-sub-dropdown {
    background: transparent;
    box-shadow: none;
  }
}
</style>
