
import { computed, defineComponent, ref } from "vue";
import { DataService } from "@/core/services/DataService";
import { useI18n } from "vue-i18n";
import store from "@/store";

export default defineComponent({
    name: "CookieInfoBar",
    setup() {
        const showCookieModal = ref(DataService.getCookieCheckbox());
        const { t } = useI18n();

        const platformConfigs = computed(() => store.getters.platformConfigs);

        const accept = () => {
            DataService.storeCookieCheckbox();
            showCookieModal.value = true;
        };

        return {
            showCookieModal,
            accept,
            t,
            platformConfigs
        };
    }
});
