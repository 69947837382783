
import {defineComponent, computed} from "vue";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = "en";

    // TODO uncomment for multiLang support
    // i18n.locale.value = localStorage.getItem("lang")
    //     ? (localStorage.getItem("lang") as string)
    //     : "en";

    const supportedLocales = computed(() => {
      return store.getters.supportedLocales;
    })

    const signOut = () => {
      store
          .dispatch(Actions.LOGOUT)
          .then(() => router.push({name: "sign-in"}));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return store.getters.supportedLocales.find(loc => loc.key === i18n.locale.value);
    });

    const userInfo = computed(() => {
      return store.getters.currentUser;
    });


    const userImg = computed(() => {
      return store.getters.currentUser.icon_path;
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      userInfo,
      supportedLocales,
      userImg
    };
  },
});
